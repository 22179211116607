import instance from "@/config/axios.config";
import { ActionContext } from "vuex";

const actions = {
  getNotify: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.get(
      `/notification/${data.userId}?page=${data.page}&size=${data.size}`
    );
    commit("setUnRead", result.data.data.unread);
    commit("setDataNotify", result.data.data.content);
    commit("setTotalPages", result.data.data.totalPages);
    return result;
  },
};

export default actions;
