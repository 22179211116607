import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesDetailsArtwork = {
  state: {
    dataDetailArtwork: [],
    isLoading: false,
  },
  mutations,
  actions,
  getters,
};

export default modulesDetailsArtwork;
