import instance from "@/config/axios.config";
import { MESSAGE_ERROR, MESSAGE_SUCCESS, USER_ID } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  submitOrder: async ({ commit }: ActionContext<any, any>, data: any) => {
    commit("setLoadingSubmitOrder", true);
    const toast = useToast();
    try {
      const result = await instance.post("/order", data);
      toast.success(MESSAGE_SUCCESS(result));
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
  getDelivery: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get("/master-data/expense");
    commit("setDataDeliveryAndTax", result.data.data);
    return result;
  },
  getCountry: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get("/master-data/country");
    commit("setDataCountry", result.data.data);
    return result;
  },
  getCity: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.get(`/master-data/country?parent=${data}`);
    commit("setDataCity", result.data.data);
    return result;
  },
  calculate: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.post("/cart/calculate", data);
    commit("setDataCalculate", result.data.data);
    return result;
  },
  checkOut: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    commit("setLoadingSubmitOrder", true);
    try {
      const result = await instance.post(
        "/order",
        data
      );
      toast.success(result.data.message)
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    } finally {
      commit("setLoadingSubmitOrder", false);
    }
  },
  updateUserAddressAndPhone: async ({ commit }: ActionContext<any, any>, data: any) => {
    const userId = localStorage.getItem(USER_ID) as string;
    const toast = useToast();
    try {
      commit("setLoadingSaveAddressAndPhone", true);
      const result = await instance.put(`/user/${userId}`, data);
      return result;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      return error;
    } finally {
      commit("setLoadingSaveAddressAndPhone", false);
    }
  },
};

export default actions;
