import instance from "@/config/axios.config";
import router from "@/router";
import path from "@/router/path";
import { ROLE, TOKEN_KEY, USER_ID, USER_INFO } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  getCode: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    try {
      commit("setLoading", { isLoading: true, account: data });
      const result = await instance.post("/auth/send-code", data);
      commit("setLoading", { isLoading: false, account: data });
      router.push(path.code);
      return result;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      commit("setLoading", { isLoading: false, account: data });
      return error;
    }
  },
  loginWithCode: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    commit("setLoadingCode", true);
    try {
      const result = await instance.post("/auth/login", data);
      localStorage.setItem(TOKEN_KEY, result.data.data.token);
      localStorage.setItem(ROLE, result.data.data.role);
      localStorage.setItem(USER_ID, result.data.data.id);
      if (result.data.data.isNew) {
        localStorage.setItem(USER_INFO, JSON.stringify(result.data.data));
        router.push(path.signupProfile);
      } else {
        router.push(path.home);
      }
      commit("setLoadingCode", false);
      return result;
    } catch (error: any) {
      commit("setLoadingCode", false);
      toast.error(error?.response?.data?.message);
    }
  },
  loginSSO: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    try {
      commit("setLoading", { isLoading: true, accountInfo: data.email });
      const result = await instance.post("/auth/login-sso", data);

      localStorage.setItem(TOKEN_KEY, result.data.data.token);
      localStorage.setItem(ROLE, result.data.data.role);
      localStorage.setItem(USER_ID, result.data.data.id);

      if (result.data.data.isNew) {
        localStorage.setItem(USER_INFO, JSON.stringify(result.data.data));
        router.push(path.signupProfile);
      } else {
        router.push(path.home);
      }

      return result;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      commit("setLoading", { isLoading: false, account: data.email });
    }
  },
  getLanguagesLogin: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.post("/locale/nation", data);
    return result;
  }
};

export default actions;
