import { convertData } from "@/utils/contants";

export interface IFormStateDiscover {
  dataSearch: any[];
  dataCategory: any[];
  dataFirstItemCategory: any[];
  dataSecondItemCategory: any[];
  dataThirdItemCategory: any[];
  dataSearchArtwork: any[];
  dataArtwork1: any[];
  dataArtwork2: any[];
  totalItem: null | number;
  totalPages: null | number;
  nameDropDown: string;
  dataArtist1: any[];
  totalItemArtist1: number;
  totalPagesArtist1: number;
}

const mutations = {
  setDataCategory: (state: IFormStateDiscover, actions: any) => {
    state.dataCategory = actions;
  },
  setDataSearch: (state: IFormStateDiscover, actions: any) => {
    state.dataSearch = actions;
  },
  setDataTrendingCategory: (state: IFormStateDiscover, actions: any) => {
    state.dataFirstItemCategory = actions[0].data.data.content;
    state.dataSecondItemCategory = actions[1].data.data.content;
    state.dataThirdItemCategory = actions[2].data.data.content;
  },
  setDataArtist1: (state: IFormStateDiscover, actions: any) => {
    state.dataArtist1 = actions.data.data.content;
    state.totalItemArtist1 = actions.data.data.totalElements;
    state.totalPagesArtist1 = actions.data.data.totalPages;
  },
  setDataSearchArtwork: (state: IFormStateDiscover, actions: any) => {
    state.dataSearchArtwork = state.dataSearchArtwork.concat(actions);
  },
  setTotalElement: (state: IFormStateDiscover, actions: any) => {
    state.totalItem = actions;
  },
  setNameDropDown: (state: IFormStateDiscover, actions: any) => {
    state.nameDropDown = actions;
  },
  setTotalPages: (state: IFormStateDiscover, actions: any) => {
    state.totalPages = actions;
  },
  resetDataSearchArtwork: (state: IFormStateDiscover) => {
    state.dataSearchArtwork = [];
  },
  resetInitState: (state: IFormStateDiscover) => {
    state.dataSearch = [];
    state.dataCategory = [];
    state.dataFirstItemCategory = [];
    state.dataSecondItemCategory = [];
    state.dataThirdItemCategory = [];
    state.dataSearchArtwork = [];
  },
};
export default mutations;
