import instance from "@/config/axios.config";
import { ActionContext } from "vuex";
import { useToast } from "vue-toastification";
import { MESSAGE_ERROR } from "@/utils/contants";

const toast = useToast();

const actions = {
  getLeftBackground: async ({ commit }: ActionContext<any, any>) => {
    try {
      const result = await instance.get(`/master-data/background?name=Left`);
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
};

export default actions;
