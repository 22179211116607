import { Language } from "@/model/language.model";
import { useToast } from "vue-toastification";
interface IFormState {
  dataNationality: any[];
  dataImage: any[];
  dataPreviewImg: any[];
  dataPrivacy: any[];
  dataDetailPrivacy: any[];
  dataAbout: any[];
  dataSupportInfo: any[];
  dataSupportCenter: any[];
  dataDetailSupportCenter: any[];
  dataVideo: string | null;
  dataPreviewVideo: string | null;
  dataLanguages: Language[];
}
const mutations = {
  setData: (state: IFormState, actions: any) => {
    state.dataNationality = actions;
  },
  setDataImage: (state: IFormState, actions: any) => {
    state.dataImage = state.dataImage.concat(actions);
  },
  setDataPreviewImg: (state: IFormState, actions: any) => {
    state.dataPreviewImg = actions;
  },
  setDataPrivacy: (state: IFormState, actions: any) => {
    state.dataPrivacy = actions.data.data;
  },
  setDataAbout: (state: IFormState, actions: any) => {
    state.dataAbout = actions.data.data;
  },
  setDataDetailPrivacy: (state: IFormState, actions: any) => {
    state.dataDetailPrivacy = actions.data.data;
  },
  setDataSupportInfo: (state: IFormState, actions: any) => {
    state.dataSupportInfo = actions.data.data;
  },
  setDataSupportCenter: (state: IFormState, actions: any) => {
    state.dataSupportCenter = actions.data.data.content;
  },
  setDataDetailSupportCenter: (state: IFormState, actions: any) => {
    state.dataDetailSupportCenter = actions.data.data.content;
  },
  setDataVideo: (state: IFormState, actions: any) => {
    state.dataVideo = actions.dataVideo;
    state.dataPreviewVideo = actions.dataPreviewVideo;
  },
  resetImage: (state: IFormState) => {
    state.dataImage = [];
    state.dataPreviewImg = [];
  },
  setIsLoading: (state: any, actions: any) => {
    state.isLoading = actions;
  },
  setDataLanguages: (state: IFormState, actions: any) => {
    state.dataLanguages = actions?.data?.data || [];
  },
};

export default mutations;
