import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesUpload = {
  state: {
    dataCategory: [],
    dataCountry: [],
    isLoading: false,
  },
  mutations,
  actions,
  getters,
};

export default modulesUpload;
