import { convertData } from "@/utils/contants";

const getters = {
  convertData: (state: any) => {
    const data1: any[] = [];
    const data2: any[] = [];
    convertData(state.dataSearchArtwork, data1, data2);
    return { data1, data2 };
  },
};
export default getters;
