
import { defineComponent, onMounted, watchEffect, computed, reactive } from "vue";
// import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
// import en from "./i18n/en";
import i18n, { loadLocaleMessages, setI18nLanguage } from "./i18n/i18n";
import router from "./router";
import path from "./router/path";
import { TOKEN_KEY } from "./utils/contants";
// import LANGUAGE from "./utils/locale";
// type MessageSchema = typeof en;
export default defineComponent({
  setup() {
    // const { locale } = useI18n<
    //   {
    //     message: MessageSchema;
    //   },
    //   LANGUAGE.VI | LANGUAGE.EN | LANGUAGE.KO
    // >({ useScope: "global" });
    const location = useRoute();
    const { state, dispatch } = useStore();
    const fontStyle = computed(() => state.fontStyle);
    const handleWatchLocal = () => {
      const token = window.localStorage.getItem(TOKEN_KEY);
      if (token) {
        window.location.reload();
      } else {
        router.push(path.home);
      }
    };
    watchEffect(() => {
      window.addEventListener("storage", handleWatchLocal);
    });
    onMounted(() => {
      const langue = JSON.parse(localStorage.getItem("defaultLocale") as string);
      const fetchTranslations = async () => {
        await loadLocaleMessages(i18n, langue?.value || "en");
        setI18nLanguage(i18n, langue?.value || "en");
      };
      fetchTranslations()

      dispatch("getFontStyle", { code: langue?.value || "en" })
      .then(res => {
        if (res.status === 200 && res.data?.data?.length) {
          if (res.data.data[0].fontFile) {
            const style = document.createElement('style');
            style.innerHTML = 
              `@font-face {
                font-family: '${fontStyle.value}';
                src: url('${process.env.VUE_APP_FONTS_PATH}/${res.data.data[0].fontFile}') format('truetype');
              }`;
            document.head.appendChild(style);
          } else {
            const link = document.createElement('link');
            link.href = `https://fonts.googleapis.com/css2?family=${fontStyle.value}&display=swap`;
            link.rel = 'stylesheet';
            document.head.appendChild(link);
          }
        }
      })
    });
    return { location, fontStyle };
  },
});
