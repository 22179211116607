import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesHomeLeft = {
  state: {
  },
  actions,
  mutations,
  getters,
};

export default modulesHomeLeft;
