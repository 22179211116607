import router from "@/router";
import path from "@/router/path";
import { TOKEN_KEY } from "@/utils/contants";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useToast } from "vue-toastification";
const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const configAxios = config;
  if (configAxios && configAxios.headers) {
    configAxios.headers["Accept-Language"] =
      JSON.parse(localStorage.getItem("defaultLocale") as string)?.value ||
      "en";
    if (token) {
      configAxios.headers.Authorization = `Bearer ${token}`;
    }
  }
  return configAxios;
});

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const toast = useToast();
    const status = error.status || error.response ? error.response?.status : 0;
    if (status === 401) {
      localStorage.clear();
      toast.error((error?.response?.data as any)?.message, { id: 401 });
      router.push(path.login);
    }
    return Promise.reject(error);
  }
);

export default instance;
