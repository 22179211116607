export interface IFormState {
  isLoading: boolean;
  isLoadingSaveAddressAndPhone: boolean;
  dataDelivery: { name: string; type: string; value: number } | null;
  dataTax: { name: string; type: string; value: number } | null;
  dataCountry: any[];
  dataCity: any[];
  dataCalculate: any;
}

const mutations = {
  setLoadingSubmitOrder: (state: IFormState, actions: any) => {
    state.isLoading = actions;
  },
  setLoadingSaveAddressAndPhone: (state: IFormState, actions: any) => {
    state.isLoadingSaveAddressAndPhone = actions;
  },
  setDataDeliveryAndTax: (state: IFormState, actions: any) => {
    state.dataDelivery = actions[0];
    state.dataTax = actions[1];
  },
  setDataCountry: (state: IFormState, actions: any) => {
    state.dataCountry = actions;
  },
  setDataCity: (state: IFormState, actions: any) => {
    state.dataCity = actions;
  },
  setDataCalculate: (state: IFormState, actions: any) => {
    state.dataCalculate = actions;
  },
};
export default mutations;
