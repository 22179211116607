const mutations = {
  setDataArtist: (state: { dataArtist: any[] }, action: any) => {
    state.dataArtist = action;
  },
  setLoading: (
    state: { dataArtist: any[]; isLoading: boolean },
    action: any
  ) => {
    state.isLoading = action;
  },
  setDataArtistNotTrending: (
    state: { dataNotTrending: any[] },
    action: any
  ) => {
    state.dataNotTrending = action;
  },
};
export default mutations;
