import { IFormLocale } from "@/model/locale.model";
import path from "@/router/path";
import LANGUAGE from "./locale";

export const MESSAGE_SUCCESS = (result: any) => result.data.message;
export const MESSAGE_ERROR = (error: any) => error?.response?.data?.message;
export const DATE_FORMAT_YYYYMMDD = "YYYY/MM/DD";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const GENDER = [
  {
    value: 0,
    label: "female",
  },
  {
    value: 1,
    label: "male",
  },
];
export const COUNT_DOWN_60S = 60;
export const COUNT_DOWN_KEY = "dont_delete_it";
export const IS_COUNT_DOWN_KEY = "is_count_down";
export const TOKEN_KEY = "nft_hi_fi";
export const USER_INFO = "user_info";
export const INFO_AFTER_SIGNUP = "new_info";
export const USER_ID = "nft_hi_fi_user";
export const SETTING_MENU = [
  {
    key: "verify",
    path: path.selectUpload,
    required: true,
  },
  {
    key: "registerArtist",
    path: "/settings/register-artist",
    required: true,
  },
  {
    key: "language",
    path: "/settings/language",
    required: true,
  },
  {
    key: "privacy",
    path: "/settings/privacy",
    required: true,
  },
  {
    key: "support",
    path: "/settings/support",
    required: true,
  },
  {
    key: "about",
    path: "/settings/about",
    required: true,
  },
];
export const LANG: IFormLocale[] = [
  {
    name: "Vietnamese",
    value: LANGUAGE.VI,
    avatar: require("@/assets/vn.png"),
  },
  {
    name: "English",
    value: LANGUAGE.EN,
    avatar: require("@/assets/en.png"),
  },
  {
    name: "Korea",
    value: LANGUAGE.KO,
    avatar: require("@/assets/korea.png"),
  },
];
export const DEFAULT_LOCALE = "defaultLocale";
export const VERIFY_INFO = "verify_info";
export const filterSelectOption = (input: string, option: any) => {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
export const DROPDOWN_DISCOVER = [
  {
    name: "Feature",
    value: 1,
  },
  {
    name: "Following",
    value: 2,
  },
];

export const BILLING_ADDRESS = "billing_address";
export const PAYMENT_CARD = "paymentCard";

export const convertData = (data: any[], arr1: any[], arr2: any[]) => {
  data.forEach((item: any, index: number) => {
    if (index < Math.round(data.length / 2)) {
      arr1.push(item);
    } else {
      arr2.push(item);
    }
  });
};

export const STATUS_ORDER = {
  CONFIRM_PAYMENT: 0,
  PREPARING_DELIVERY: 1,
  ON_DELIVERY: 2,
  DELIVERED: 3,
  REQUEST_RETURN: 4,
  RETURNED: 5,
  REQUEST_CANCEL: 6,
  CANCELED: 7,
};

export const STATUS_ORDER_SELECT = [
  {
    value: 1,
    name: "status1",
  },
  {
    value: 2,
    name: "status2",
  },
  {
    value: 3,
    name: "status3",
  },
  {
    value: 4,
    name: "status4",
  },
  {
    value: 5,
    name: "status5",
  },
  {
    value: 6,
    name: "status6",
  },
  {
    value: 7,
    name: "status7",
  },
];

export const MAX_LENGTH_TITLE = 100;
export const MAX_LENGTH_DESCRIPTION = 4000;

export const URL_TO_FILE = async (url: string, filename: string, mimeType: string) => {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const dataURItoBlob = (base64Data: string): Blob => {
  let byteString;
  if (base64Data.split(",")[0].indexOf("base64") >= 0) byteString = atob(base64Data.split(",")[1]);
  else byteString = base64Data.split(",")[1];
  const mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

export const handleMouseLeave: <T extends Record<string, any>>(
  initState: T,
  value: keyof T
) => void = (initState, value) => {
  if (typeof initState[value] === "string") {
    initState[value] = initState[value].trim();
  }
};

export const PHONE = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
];

export const MAX_SIZE = 100;
export const MAX_SIZE_VIDEO = 8;
export const MAX_FILE_SIZE = 10;
export const MIN_CHARACTERS = 6;
export const ROLE = "role";
export const MIN_PRICE = 0.5;
export const MAX_PRICE = 999999.99;
export const SEARCH_DISCOVER = "discover";
export const SEARCH_POPULAR = "popular";
export const SEARCH_TRENDING = "trending";
export enum SELECT_UPLOAD {
  PASSPORT = "one",
  ID = "two",
}

export enum STATUS_KYC {
  WAITING = 0,
  REJECT = 1,
  ACCEPT = 2,
}

export enum STATUS_REQUEST_ARTIST {
  WAITING = 0,
  REJECT = 1,
  ACCEPT = 2,
}

export const SYSTEM_ROLE = {
  ADMIN: "Admin",
  ARTIST: "Artist",
  USER: "User",
};

export const PUBLIC_ROUTES = [
  "Login", "Code", "Home", "PopularArtwork", "TrendingArtist", "Profile",
  "DetailsArtWork", "Discover", "Setting", "About", "Language", "Privacy",
  "Privacy Detail", "SupportCenter", "Support Detail"
];