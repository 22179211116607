import instance from "@/config/axios.config";
import router from "@/router";
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  getDetailOrder: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.get(`/order/${data.id}`);
    commit("setDataDetail", result.data.data);
    return result;
  },
  cancelOrder: async (
    { commit }: ActionContext<any, any>,
    { orderId, value }: { orderId: string; value: FormData }
  ) => {
    const toast = useToast();
    commit("setLoadingDetailOrder", true);
    try {
      const result = await instance.put(`/order/return/${orderId}`, value);
      commit("setLoadingDetailOrder", false);
      toast.success(MESSAGE_SUCCESS(result));
      router.push(`/order/detail/${orderId}`);
      return result;
    } catch (error) {
      commit("setLoadingDetailOrder", false);
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
  cancelOrderDetail: async (
    { commit, dispatch }: ActionContext<any, any>,
    { orderId, ...data }: { orderId: string }
  ) => {
    const toast = useToast();
    commit("setIsLoadingCancel", true);
    try {
      const result = await instance.put(`/order/cancel/${orderId}`, data);
      toast.success(MESSAGE_SUCCESS(result));
      dispatch("getDetailOrder", { id: orderId });
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    } finally {
      commit("setIsLoadingCancel", false);
    }
  },
  updateStatusOrder: async (
    { dispatch, commit }: ActionContext<any, any>,
    { orderId, formData }: any
  ) => {
    const toast = useToast();
    commit("setIsLoadingUpdateStatus", true);
    try {
      const result = await instance.put(`/order/${orderId}`, formData);
      toast.success(MESSAGE_SUCCESS(result));
      dispatch("getDetailOrder", { id: orderId });
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    } finally {
      commit("setIsLoadingUpdateStatus", false);
    }
  },
};

export default actions;
