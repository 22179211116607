import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesSettings = {
  state: {
    dataNationality: [],
    dataImage: [],
    dataPreviewImg: [],
    dataPrivacy: [],
    dataDetailPrivacy: [],
    dataAbout: [],
    dataSupportInfo: [],
    dataSupportCenter: [],
    dataDetailSupportCenter: [],
    dataVideo: null,
    dataPreviewVideo: null,
    isLoading: false,
    dataLanguages: []
  },
  mutations,
  actions,
  getters,
};

export default modulesSettings;
