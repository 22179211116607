const mutations = {
  setDataDetailArtwork: (state: { dataDetailArtwork: any[] }, action: any) => {
    state.dataDetailArtwork = action;
  },
  setIsLoading: (
    state: { dataDetailArtwork: any[]; isLoading: boolean },
    action: any
  ) => {
    state.isLoading = action;
  },
};
export default mutations;
