import { createStore } from "vuex";
import modulesLogin from "@/page/Login/module";
import modulesUpdate from "@/page/SignUpProfile/module";
import modulesFollow from "@/page/Follow/module";
import modulesProfile from "@/page/Profile/module";
import modulesSettings from "@/page/Setting/module";
import modulesUpload from "@/page/Upload/module";
import modulesHome from "@/page/Home/module";
import modulesHomeLeft from "@/page/HomeLeft/module";
import modulesDetailsArtwork from "@/page/Details/module";
import modulesDiscover from "@/page/Discover/module";
import modulesCheckout from "@/page/Checkout/module";
import modulesCart from "@/page/Cart/module";
import modulesNotify from "@/page/Notify/module";
import modulesDetailOrder from "@/page/DetailOrder/module";
import modulesOrder from "@/page/Order/module";
import { ActionContext } from "vuex";
import instance from "@/config/axios.config";
export default createStore({
  state: {
    fontStyle: "",
  },
  getters: {},
  mutations: {
    setFontStyle: (state: any, action: any) => {
      state.fontStyle = action;
    },
  },
  actions: {
    getFontStyle: async ({ commit }: ActionContext<any, any>, data: any) => {
      try {
        const result = await instance.post(`/locale/nation`, data);
        if (result?.data?.data?.length) {
          commit("setFontStyle", result.data?.data[0]?.font);
        }
        return result;
      } catch (error) {
        return error;
      }
    }
  },
  modules: {
    modulesLogin,
    modulesUpdate,
    modulesFollow,
    modulesProfile,
    modulesSettings,
    modulesUpload,
    modulesHome,
    modulesHomeLeft,
    modulesDetailsArtwork,
    modulesDiscover,
    modulesCheckout,
    modulesCart,
    modulesNotify,
    modulesDetailOrder,
    modulesOrder,
  },
});
