import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesProfile = {
  state: {
    dataUser: [],
    favoriteArtworks: [],
    isLoading: false,
  },
  mutations,
  actions,
  getters,
};

export default modulesProfile;
