import { TOKEN_KEY } from "./contants";

const useAuth = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    return true;
  } else {
    return false;
  }
};

export default useAuth;
