export interface IFormState {
  dataDetail: any;
  isLoading: boolean;
  isLoadingUpdateStatus: boolean;
  isLoadingCancel: boolean;
}

const mutations = {
  setDataDetail: (state: IFormState, actions: any) => {
    state.dataDetail = actions;
  },
  setLoadingDetailOrder: (state: IFormState, actions: any) => {
    state.isLoading = actions;
  },
  setIsLoadingUpdateStatus: (state: IFormState, actions: any) => {
    state.isLoadingUpdateStatus = actions;
  },
  setIsLoadingCancel: (state: IFormState, actions: any) => {
    state.isLoadingCancel = actions;
  },
};
export default mutations;
