import axios from "axios";
import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
// import en from "./en";
// import ko from "./ko";
// import vi from "./vi";

// const messages = {
//   en,
//   ko,
//   vi,
// };

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en",
  messages: {},
});
export function setI18nLanguage(i18n: any, locale: string) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.querySelector('html')!.setAttribute('lang', locale);
}

export async function loadLocaleMessages(i18n: any, locale: string) {
  const response = await axios.get(`${process.env.VUE_APP_LOCALES_PATH}/${locale}.json`);
  i18n.global.setLocaleMessage(locale, response.data || {});
  return nextTick();
}
export default i18n;
