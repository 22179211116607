const mutations = {
  setBuyOrders: (state: { orders: any[] }, action: any) => {
    state.orders = action;
  },
  setSellOrders: (state: { orders: any[] }, action: any) => {
    state.orders = action;
  },
  setLoading: (state: { dataUser: any[]; isLoading: boolean }, action: any) => {
    state.isLoading = action;
  },
};
export default mutations;
