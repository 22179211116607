import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesDiscover = {
  state: {
    dataSearch: [],
    dataCategory: [],
    dataFirstItemCategory: [],
    dataSecondItemCategory: [],
    dataThirdItemCategory: [],
    dataSearchArtwork: [],
    dataArtwork1: [],
    dataArtwork2: [],
    totalItem: null,
    totalPages: null,
    nameDropDown: "",
    dataArtist1: [],
    totalItemArtist1: 0,
    totalPagesArtist1: 0,
  },
  mutations,
  actions,
  getters,
};

export default modulesDiscover;
