interface IFormState {
  dataFeature: any[];
  dataPopular: any[];
  dataTrending: any[];
  totalPages: number;
}
const mutations = {
  setDataFeature: (state: IFormState, actions: any) => {
    state.dataFeature = actions;
  },
  setDataPopular: (state: IFormState, actions: any) => {
    state.dataPopular = actions;
  },
  setDataTrending: (state: IFormState, actions: any) => {
    state.dataTrending = state.dataTrending.concat(actions.data.data.content);
    state.totalPages = actions.data.data.totalPages;
  },
  resetDataTrending: (state: IFormState) => {
    state.dataTrending = [];
  },
};

export default mutations;
