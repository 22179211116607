import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesCart = {
  state: {
    dataCart: [],
  },
  mutations,
  actions,
  getters,
};

export default modulesCart;
