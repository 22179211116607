import instance from "@/config/axios.config";
import router from "@/router";
import path from "@/router/path";
import { MESSAGE_ERROR, MESSAGE_SUCCESS, USER_ID } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  getDataDetailArtwork: async (
    { commit }: ActionContext<any, any>,
    data: any
  ) => {
    const result = await instance.get(`/artwork/${data}`);
    commit("setDataDetailArtwork", result.data.data[0]);
    return result;
  },
  addReview: async (_: any, data: any) => {
    const toast = useToast();
    try {
      const result = await instance.post("/review", data);
      toast.success(MESSAGE_SUCCESS(result));
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
  addToCart: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    try {
      commit("setIsLoading", true);
      const result = await instance.post("/cart", data);
      toast.success(MESSAGE_SUCCESS(result));
      commit("setIsLoading", false);
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      commit("setIsLoading", false);
      return error;
    }
  },
  deleteArtwork: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    try {
      const result = await instance.delete("/artwork/delete", { data });
      toast.success(MESSAGE_SUCCESS(result));
      router.push(`${path.profile}?user_id=${localStorage.getItem(USER_ID)}`);
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
  changeFavoriteStatus: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    try {
      const result = await instance.put("/artwork/favorite", data);
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
};

export default actions;
