export interface IFormState {
  dataCart: any[];
}

const mutations = {
  setDataCart: (state: IFormState, actions: any) => {
    state.dataCart = actions;
  },
};
export default mutations;
