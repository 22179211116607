import instance from "@/config/axios.config";
import { USER_ID } from "@/utils/contants";
import { ActionContext } from "vuex";

const actions = {
  getDataFeature: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.post(
      `/artwork/featured?page=${data.page}&size=${data.size}`,
      data
    );
    commit("setDataFeature", result.data.data.content);
  },
  getDataPopular: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.post(
      `/artwork/popular?page=${data.page}&size=${data.size}`,
      data
    );
    commit("setDataPopular", result.data.data.content);
    return result;
  },
  getDataTrending: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.post(
      `/user/artists?page=${data.page}&size=${data.size}&sort=trending`,
      data
    );
    commit("setDataTrending", result);
    return result;
  },
  getDataFollowing: async ({ commit }: ActionContext<any, any>, { page, size, ...data }: any) => {
    const result = await instance.post(`/artwork/following?page=${page}&size=${size}`, data);
    return result;
  },
  getDataSuggested: async ({ commit }: ActionContext<any, any>, { page, size }: any) => {
    const result = await instance.get(`/artwork/suggested?page=${page}&size=${size}`);
    return result;
  },
  updateStatus: async ({ commit, dispatch }: ActionContext<any, any>) => {
    const userId = localStorage.getItem(USER_ID);
    const result = await instance.put(`/notification/${userId}`);
    dispatch("getNotify");
    return result;
  },
};

export default actions;
