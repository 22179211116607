const path = {
  home: "/home",
  login: "/login",
  cart: "/cart",
  code: "/code",
  signupProfile: "/signup-profile",
  follow: "/follow",
  profile: "/profile",
  editProfile: "/edit-profile",
  setting: "/settings",
  verify: "/settings/verify",
  kyc: "/settings/verify/kyc",
  language: "/settings/language",
  privacy: "/settings/privacy",
  privacyDetail: "/settings/privacy/:name",
  support: "/settings/support",
  supportDetail: "/settings/support/:name",
  about: "/settings/about",
  preview: "/settings/verify/kyc/preview",
  upload: "/upload-artwork",
  details: "/details/:id",
  registerArtist: "/settings/register-artist",
  popularArtwork: "/popular-artwork",
  trendingArtist: "/trending-artist",
  discover: "/discover",
  discoverResult: "/discover/result",
  checkout: "/checkout/:id",
  cartItem: "/cart",
  billingAddress: "/checkout/billing-address",
  notify: "/notify",
  success: "/success",
  cancel: "/cancel",
  order: "/order",
  detailOrder: "/order/detail/:id",
  reasonCancel: "/reason-cancel/:id",
  selectUpload: "/select-upload",
  card: "/card",
  add_new_card: "/add-new-card",
};
export default path;
