import instance from "@/config/axios.config";
import { MESSAGE_ERROR, MESSAGE_SUCCESS, USER_ID } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  getCartInfo: async ({ commit }: ActionContext<any, any>) => {
    const userId = localStorage.getItem(USER_ID);
    const result = await instance.get(`/cart/${userId}`);
    commit("setDataCart", result.data.data[0].items);
    return result.data.data[0].items;
  },
  deleteItemCart: async (_: any, data: any) => {
    const toast = useToast();
    try {
      const result = await instance.delete("/cart", { data: data });
      toast.success(MESSAGE_SUCCESS(result));
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
};

export default actions;
