const mutations = {
  setSSOPopUpOpen(state: any, actions: any) {
    state.isSSOPopUpOpen = actions;
  },
  setLoading(state: { isLoading: boolean; accountInfo: string }, actions: any) {
    state.isLoading = actions.isLoading;
    state.accountInfo = actions.account;
  },
  setLoadingCode: (state: any, actions: any) => {
    state.isLoadingCode = actions;
  },
  setLocale: (
    state: { isLoading: boolean; accountInfo: string; locale: string },
    actions: any
  ) => {
    state.locale = actions;
  },
};

export default mutations;
