import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesNotify = {
  state: {
    dataNotify: [],
    unRead: null,
    totalPages: 0,
  },
  actions,
  mutations,
  getters,
};

export default modulesNotify;
