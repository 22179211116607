import instance from "@/config/axios.config";
import { MESSAGE_ERROR, MESSAGE_SUCCESS, USER_ID } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  getNationality: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get("/master-data/country");
    const newData = result.data.data
      .map((item: any) => {
        if (item.value) {
          return { label: item.name, value: item.name };
        }
      })
      .filter((item: any) => item !== undefined);
    commit("setData", newData);
    return result;
  },
  uploadKYC: async (_: any, data: any) => {
    const user_id = localStorage.getItem(USER_ID);
    const toast = useToast();
    try {
      const result = await instance.put(`/user/kyc/${user_id}`, data);
      return result;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      return error;
    }
  },
  registerArtist: async (_: any, data: any) => {
    const user_id = localStorage.getItem(USER_ID);
    const toast = useToast();
    try {
      const result = await instance.put(
        `/user/register-artist/${user_id}`,
        data
      );
      toast.success(MESSAGE_SUCCESS(result));
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
  getPrivacy: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get(`/master-data/privacy`);
    commit("setDataPrivacy", result);
    return result;
  },
  getDetailPrivacy: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.get(`/master-data/privacy?name=${data.name}`);
    commit("setDataDetailPrivacy", result);
    return result;
  },
  getDataAbout: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get("/master-data/about");
    commit("setDataAbout", result);
    return result;
  },
  getDataSupportInfo: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get("/master-data/support%20info");
    commit("setDataSupportInfo", result);
    return result;
  },
  getDataSupportCenter: async (
    { commit }: ActionContext<any, any>,
    data: any
  ) => {
    const result = await instance.post("/master-data?page=1&size=100", data);
    commit("setDataSupportCenter", result);
    return result;
  },
  getDataDetailSupportCenter: async (
    { commit }: ActionContext<any, any>,
    data: any
  ) => {
    const result = await instance.post("/master-data?page=1&size=100", data);
    commit("setDataDetailSupportCenter", result);
    return result;
  },
  getAllLanguages: async (
    { commit }: ActionContext<any, any>,
    data: any
  ) => {
    const result = await instance.post("/locale/nation", data);
    commit("setDataLanguages", result);
    return result;
  }
};

export default actions;
