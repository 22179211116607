import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-child" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ 'font-family': `'${_ctx.fontStyle}'` }),
    class: _normalizeClass(
      _ctx.location.path === '/home' ||
      _ctx.location.path === '/discover' ||
      _ctx.location.path === '/profile' ||
      _ctx.location.path === '/order'
        ? 'app'
        : 'app2'
    )
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ], 6))
}