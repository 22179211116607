const mutations = {
  setCategory: (state: { dataCategory: any[] }, action: any) => {
    state.dataCategory = action;
  },
  setCountry: (state: { dataCountry: any[] }, action: any) => {
    state.dataCountry = action;
  },
  setLoading: (
    state: { dataCategory: any[]; isLoading: boolean },
    action: any
  ) => {
    state.isLoading = action;
  },
};
export default mutations;
