import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesUpdate = {
  state: {
    isLoading: false,
  },
  mutations,
  actions,
  getters,
};

export default modulesUpdate;
