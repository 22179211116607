import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesCheckout = {
  state: {
    isLoading: false,
    isLoadingSaveAddressAndPhone: false,
    dataDelivery: null,
    dataTax: null,
    dataCountry: [],
    dataCity: [],
    dataCalculate: null,
  },
  mutations,
  actions,
  getters,
};

export default modulesCheckout;
