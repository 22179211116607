import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesDetailOrder = {
  state: {
    isLoading: false,
    dataDetail: null,
    isLoadingUpdateStatus: false,
    isLoadingCancel: false,
  },
  mutations,
  actions,
  getters,
};

export default modulesDetailOrder;
