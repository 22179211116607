import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesHome = {
  state: {
    dataFeature: [],
    dataPopular: [],
    dataTrending: [],
    totalPages: 0,
  },
  mutations,
  actions,
  getters,
};

export default modulesHome;
