import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { createApp } from "vue";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "swiper/css";

import App from "./App.vue";
import i18n from "./i18n/i18n";
import router from "./router";
import store from "./store";
import SwiperClass, { Autoplay } from "swiper";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueLazyload from "vue-lazyload";
import timeago from "vue-timeago3";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import VueCreditCardValidation from "vue-credit-card-validation";
import "./main.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

const options: PluginOptions = {
  timeout: 2000,
  position: POSITION.TOP_RIGHT,
  // pauseOnHover: true,
  hideProgressBar: false,
  transition: "Vue-Toastification__fade",
  draggable: true,
  closeButton: "button",
  closeOnClick: true,
  toastClassName: "toast-content",
};
const timeagoOptions = {
  converterOptions: {
    includeSeconds: true,
  },
};

SwiperClass.use([Autoplay]);

createApp(App)
  .use(router)
  .use(i18n)
  .use(store)
  .use(Antd)
  .use(timeago, timeagoOptions)
  .use(VueAwesomeSwiper)
  .use(Toast, options)
  .use(VueCreditCardValidation)
  .component("QuillEditor", QuillEditor)
  .use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1,
    loading: require("@/assets/test123.gif"),
  })
  .mount("#app");
