import instance from "@/config/axios.config";
import router from "@/router";
import path from "@/router/path";
import { INFO_AFTER_SIGNUP, USER_INFO } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  updateUser: async ({ commit }: ActionContext<any, any>, data: any) => {
    const userId = JSON.parse(localStorage.getItem(USER_INFO) as string).id;
    const toast = useToast();
    try {
      commit("setLoading", true);
      const result = await instance.put(`/user/${userId}`, data);
      commit("setLoading", false);
      localStorage.setItem(INFO_AFTER_SIGNUP, JSON.stringify(result.data.data));
      return result;
    } catch (error: any) {
      commit("setLoading", false);
      toast.error(error?.response?.data?.message);
      return error;
    }
  },
};

export default actions;
