import instance from "@/config/axios.config";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  getUserProfile: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.get(`/user/${data}`);
    commit("setUser", result.data.data);
    return result;
  },
  getDetailArtist: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.get(`/user/artists/${data}`);
    commit("setUser", result.data.data);
    return result;
  },
  updateProfile: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    commit("setLoading", true);
    try {
      const result = await instance.put(
        `/user/${data.user_id}`,
        data.form_data
      );
      commit("setLoading", false);
      toast.success(result.data.message);
      return result;
    } catch (error: any) {
      commit("setLoading", false);
      toast.error(error.response.data.message);
      return error;
    }
  },
  getUserFavorites: async ({ commit }: ActionContext<any, any>, data: any) => {
    const result = await instance.post(`/artwork/favorite`, data);
    commit("setFavoriteArtworks", result.data.data);
    return result;
  },
};

export default actions;
