import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesFollow = {
  state: {
    dataArtist: [],
    isLoading: false,
    dataNotTrending: [],
  },
  mutations,
  actions,
  getters,
};

export default modulesFollow;
