import instance from "@/config/axios.config";
import { ActionContext } from "vuex";

const actions = {
  getOrderByBuyer: async ({ commit }: ActionContext<any, any>, { page, size, ...data }: any) => {
    const result = await instance.post(`/order/search-by-buyer?page=${page}&size=${size}`, data);
    commit("setBuyOrders", result.data.data.content);
    return result;
  },
  getOrderBySeller: async ({ commit }: ActionContext<any, any>, { page, size, ...data }: any) => {
    const result = await instance.post(`/order/search-by-seller?page=${page}&size=${size}`, data);
    commit("setSellOrders", result.data.data.content);
    return result;
  },
};

export default actions;
