interface IFormState {
  dataNotify: any[];
  unRead: number | null;
  totalPages: number;
}

const mutations = {
  setDataNotify: (state: IFormState, actions: any) => {
    state.dataNotify = state.dataNotify.concat(actions);
  },
  setUnRead: (state: IFormState, actions: any) => {
    state.unRead = actions;
  },
  setTotalPages: (state: IFormState, actions: any) => {
    state.totalPages = actions;
  },
  resetDataNotify: (state: IFormState) => {
    state.dataNotify = [];
  },
};
export default mutations;
