const mutations = {
  setUser: (state: { dataUser: any[] }, action: any) => {
    state.dataUser = action;
  },
  setLoading: (state: { dataUser: any[]; isLoading: boolean }, action: any) => {
    state.isLoading = action;
  },
  setFavoriteArtworks: (state: { favoriteArtworks: any[] }, action: any) => {
    state.favoriteArtworks = action;
  },
};
export default mutations;
