import instance from "@/config/axios.config";
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";
import { IFormStateDiscover } from "./mutations";

const actions = {
  getCategory: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get("/master-data/category");
    commit("setDataCategory", result.data.data);
    return result;
  },
  searchFromCategory: async (
    { commit }: ActionContext<IFormStateDiscover, any>,
    data: any
  ) => {
    const result = await instance.get(
      `/artwork/category/${data.category}?page=${data.page}&size=${data.size}`
    );
    commit("setDataSearch", result.data.data.content);
    commit("setTotalPages", result.data.data.totalPages);
    return result;
  },
  searchArtwork: async (
    { commit, dispatch }: ActionContext<IFormStateDiscover, any>,
    data: any
  ) => {
    const toast = useToast();
    try {
      const resultArtwork = await instance.post(
        `/artwork/search-public?page=${data.page}&size=${data.size}`,
        {
          title: data.title,
          isRegistered: 2,
        }
      );
      commit("setDataSearchArtwork", resultArtwork.data.data.content);
      commit("setTotalElement", resultArtwork.data.data.totalElements);
      commit("setTotalPages", resultArtwork.data.data.totalPages);
      dispatch("getArtist", {
        name: data.name,
        page: data.page,
        size: data.size,
      });
      return resultArtwork;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
  searchFollowing: async (
    { commit }: ActionContext<any, any>,
    { page, size, ...data }: any
  ) => {
    const toast = useToast();
    try {
      const result = await instance.post(
        `/artwork/following?page=${page}&size=${size}`,
        data
      );
      commit("setDataSearchArtwork", result.data.data.content);
      commit("setTotalPages", result.data.data.totalPages);
      commit("setTotalElement", result.data.data.totalElements);
      commit("setNameDropDown", "Following");
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
  getArtistFollowing: async (
    { commit }: ActionContext<any, any>,
    { page, size, ...rest }: any
  ) => {
    const result = await instance.post(
      `/user/artists?page=${page}&size=${size}&sort=trending&following=1`,
      rest
    );
    commit("setDataArtist1", result);
    return result;
  },
};

export default actions;
