import { IFormNewInfo, IFormUserInfo } from "@/model/userInfo.model";
import { INFO_AFTER_SIGNUP, USER_INFO, PUBLIC_ROUTES } from "@/utils/contants";
import useAuth from "@/utils/useAuth";
import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  createWebHistory,
} from "vue-router";
import path from "./path";

const routes: Array<RouteRecordRaw> = [
  {
    name: "LayoutMain",
    path: "",
    component: () => import("@/page/LayoutMain.vue"),
    children: [
      {
        path: "",
        name: "Layout",
        component: () => import("@/page/Layout.vue"),
        children: [
          {
            path: path.home,
            name: "Home",
            component: () => import("@/page/Home/Home.vue"),
          },
          {
            path: path.discover,
            name: "Discover",
            component: () => import("@/page/Discover/Discover.vue"),
          },
          {
            path: path.profile,
            name: "Profile",
            component: () => import("@/page/Profile/Profile.vue"),
          },
          {
            path: path.cart,
            name: "Cart",
            component: () => import("@/page/Cart/Cart.vue"),
          },
          {
            path: path.order,
            name: "Order",
            component: () => import("@/page/Order/ListOrders.vue"),
          },
          {
            path: path.card,
            name: "Card",
            component: () => import("@/page/Card/cardPage.vue"),
          },
          {
            path: path.add_new_card,
            name: "addNewCard",
            component: () => import("@/page/Card/addNewCard.vue"),
          },
        ],
      },
      {
        path: path.checkout,
        name: "Checkout",
        component: () => import("@/page/Checkout/Checkout.vue"),
      },
      {
        path: path.detailOrder,
        name: "Detail Order",
        component: () => import("@/page/DetailOrder/DetailOrder.vue"),
      },
      {
        path: path.follow,
        name: "Follow",
        component: () => import("@/page/Follow/Follow.vue"),
      },
      {
        path: path.registerArtist,
        name: "RegisterArtist",
        component: () => import("@/page/Setting/RegisterArtist.vue"),
      },
      {
        path: path.editProfile,
        name: "EditProfile",
        component: () => import("@/page/EditProfile/EditProfile.vue"),
      },
      {
        path: path.setting,
        name: "Setting",
        component: () => import("@/page/Setting/Setting.vue"),
      },
      {
        path: path.about,
        name: "About",
        component: () => import("@/page/Setting/About.vue"),
      },
      {
        path: path.language,
        name: "Language",
        component: () => import("@/page/Setting/Language.vue"),
      },
      {
        path: path.privacy,
        name: "Privacy",
        component: () => import("@/page/Setting/Privacy.vue"),
      },
      {
        path: path.privacyDetail,
        name: "Privacy Detail",
        component: () => import("@/page/Setting/DetailPrivacy.vue"),
      },
      {
        path: path.support,
        name: "SupportCenter",
        component: () => import("@/page/Setting/SupportCenter.vue"),
      },
      {
        path: path.supportDetail,
        name: "Support Detail",
        component: () => import("@/page/Setting/DetailSupportCenter.vue"),
      },
      {
        path: path.verify,
        name: "Verify",
        component: () => import("@/page/Setting/Verify.vue"),
      },
      {
        path: path.selectUpload,
        name: "SelectUpload",
        component: () => import("@/page/Setting/SelectUpload.vue"),
      },
      {
        path: path.kyc,
        name: "KYC",
        component: () => import("@/page/Setting/KYC.vue"),
      },
      {
        path: path.preview,
        name: "Preview",
        component: () => import("@/page/Setting/PreviewImg.vue"),
      },
      {
        path: path.upload,
        name: "UploadArtWork",
        component: () => import("@/page/Upload/UploadArtWork.vue"),
      },
      {
        path: path.details,
        name: "DetailsArtWork",
        component: () => import("@/page/Details/Details.vue"),
      },
      {
        path: path.popularArtwork,
        name: "PopularArtwork",
        component: () => import("@/page/PopularArtwork/PopularArtwork.vue"),
      },
      {
        path: path.trendingArtist,
        name: "TrendingArtist",
        component: () => import("@/page/TrendingArtist/TrendingArtist.vue"),
      },
      {
        path: path.billingAddress,
        name: "BillingAddress",
        component: () => import("@/page/Checkout/BillingAddress.vue"),
      },
      {
        path: path.notify,
        name: "Notify",
        component: () => import("@/page/Notify/Notify.vue"),
      },
      {
        path: path.reasonCancel,
        name: "Reason Cancel",
        component: () => import("@/page/DetailOrder/Reason.vue"),
      },
    ],
  },

  {
    path: "",
    name: "LayoutLogin",
    component: () => import("@/page/LayoutLogin.vue"),
    children: [
      {
        path: path.login,
        name: "Login",
        component: () => import("@/page/Login/Login.vue"),
      },
      {
        path: path.code,
        name: "Code",
        component: () => import("@/page/Code/Code.vue"),
      },
      {
        path: path.signupProfile,
        name: "SignUpProfile",
        component: () => import("@/page/SignUpProfile/SignUpProfile.vue"),
      },
    ],
  },
  {
    path: path.success,
    name: "Success",
    component: () => import("@/page/Success/Success.vue"),
  },
  {
    path: path.cancel,
    name: "Cancel",
    component: () => import("@/page/Success/Cancel.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// private route

router.beforeEach((to, from, next) => {
  const token = useAuth();
  const userInfo: IFormUserInfo = JSON.parse(
    localStorage.getItem(USER_INFO) as string
  );
  const newInfo: IFormNewInfo = JSON.parse(
    localStorage.getItem(INFO_AFTER_SIGNUP) as string
  );
  if (token) {
    if (
      userInfo?.isNew &&
      newInfo?.name &&
      to.name !== "Follow" &&
      to.name !== "SelectUpload"
    ) {
      return next(path.follow);
    }
    if (userInfo?.isNew && !newInfo?.name && to.name !== "SignUpProfile") {
      return next(path.signupProfile);
    }
    if (
      !userInfo &&
      !newInfo &&
      (to.name === "Login" ||
        to.name === "Code" ||
        to.name === "Follow" ||
        to.name === "SignUpProfile" ||
        to.name === "Layout")
    ) {
      return next(path.home);
    }
  } else {
    if (to.path === '/') {
      return next(path.home);
    }
    if (!PUBLIC_ROUTES.find(route => route === to.name)) {
      return next(path.login);
    }
  }
  next();
});

export default router;
