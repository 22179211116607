import instance from "@/config/axios.config";
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from "@/utils/contants";
import { useToast } from "vue-toastification";
import { ActionContext } from "vuex";

const actions = {
  getUploadArtworkCategory: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get("/master-data/category");
    const newArr = result.data.data.map((item: any) => {
      return { ...item, label: item.name };
    });
    commit("setCategory", newArr);
    return newArr;
  },
  getShippingDomesticCountry: async ({ commit }: ActionContext<any, any>) => {
    const result = await instance.get("/master-data/country");
    const country = result.data.data
      .map((item: any) => {
        if (item.value) {
          return { label: item.name, value: item.name };
        }
      })
      .filter((item: any) => item !== undefined);
      
    commit("setCountry", country);
    return result;
  },
  uploadArtWork: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    commit("setLoading", true);
    try {
      const result = await instance.post("artwork", data);
      toast.success(result.data.message);
      commit("setLoading", false);
      return result;
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      commit("setLoading", false);
      return error;
    }
  },
  updateArtwork: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    try {
      const result = await instance.put(`/artwork/update/${data.id}`, data.value);
      toast.success(MESSAGE_SUCCESS(result));
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
  uploadArtworkFileS3: async ({ commit }: ActionContext<any, any>, data: any) => {
    const toast = useToast();
    try {
      const result = await instance.post(`/artwork`, data);
      toast.success(MESSAGE_SUCCESS(result));
      return result;
    } catch (error) {
      toast.error(MESSAGE_ERROR(error));
      return error;
    }
  },
};

export default actions;
