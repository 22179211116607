import instance from "@/config/axios.config";
import router from "@/router";
import path from "@/router/path";
import { INFO_AFTER_SIGNUP, USER_ID, USER_INFO } from "@/utils/contants";
import { ActionContext } from "vuex";

const actions = {
  getArtist: async (
    { commit }: ActionContext<any, any>,
    { page, size, ...rest }: any
  ) => {
    const result = await instance.post(
      `/user/artists?size=${size}&page=${page}&sort=trending`,
      rest
    );
    commit("setDataArtist", result.data.data.content);
    return result;
  },
  getArtistNotTrending: async (
    { commit }: ActionContext<any, any>,
    { page, size, ...rest }: any
  ) => {
    const result = await instance.post(
      `/user/artists?size=${size}&page=${page}`,
      rest
    );
    commit("setDataArtistNotTrending", result.data.data.content);
    return result;
  },
  followUser: async ({ commit }: ActionContext<any, any>, data: any) => {
    try {
      const user_id = JSON.parse(localStorage.getItem(USER_INFO) as string).id;
      commit("setLoading", true);
      const result = await instance.put("/user/follow", data);
      commit("setLoading", false);
      localStorage.removeItem(INFO_AFTER_SIGNUP);
      localStorage.removeItem(USER_INFO);
      localStorage.setItem(USER_ID, user_id);
      router.push(path.home);
      return result;
    } catch (error) {
      commit("setLoading", false);
      return error;
    }
  },
  followTrendingArtist: async (_: any, data: any) => {
    try {
      const result = await instance.put("/user/follow", data);
      return result;
    } catch (error) {
      return error;
    }
  },
  unFollowArtist: async (_: any, data: any) => {
    const result = await instance.put("/user/unfollow", data);
    return result;
  },
};
export default actions;
