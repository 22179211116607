import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const modulesLogin = {
  state: {
    isSSOPopUpOpen: false,
    isLoading: false,
    accountInfo: "",
    locale: "vi",
    isLoadingCode: false,
  },
  mutations,
  actions,
  getters,
};

export default modulesLogin;
